.contact {
    height: 100%;
    width: 100%;

    display: flex;
}

.social-media-container {
    display: flex;
    flex: 1;

    align-content: center;
}

.social-media-link {
    flex: 1;

    align-self: center;

    color: #551A8B;

	text-decoration: none;
    font-size: 16px;

}