.upcoming {
    height: 100%;
    width: 100%;
}

.upcoming-poster-img {
    position: absolute;

    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;

    max-height: 100%;
    max-width: 100%;
}