.gallery-img-container {
	position: relative;

	flex-grow: 1;

	height: 40vh;

	cursor: pointer;
}

.gallery-img {
	height: 100%;
	min-width: 100%;
	max-width: 100%;

	object-fit: cover;
}