.not-found {
    display: flex;

    justify-content: center;
    align-items: center;

	width: 100%;
	height: 100%;

	background-image: url("../../Media/NotFound/rads3.gif");
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
}

.not-found-container {
    display: flex;

    flex-direction: column;

    align-items: center;
}

.not-found-logo-img {
    height: 200px;
    width: 200px;

    filter: brightness(0) saturate(100%) invert(30%) sepia(65%) saturate(2%) hue-rotate(93deg) brightness(96%) contrast(96%);

    pointer-events: none;
}

.not-found-heading {
    color: #555;
}

.not-found-spacer {
    height: 150px;
}