.navigation {
    display: flex;

	height: 100%;
	width: 100%;

    background-image: url("../../Media/Navigation/rads1.gif");
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
}

.navigation-circle-container {
	flex: 1;

	margin: 4%;
}

.navigation-circle-list {
	height: 100%;
	width: 100%;

	margin: 0;
	padding: 0;
}

.navigation-circle-item {
	position: absolute;

	display: flex;

	list-style-type: none;

	z-index: 10;

	width: 100px;
}

.navigation-circle-center-logo-img {
	/* display: none; */
	position: fixed;
	
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;

	margin: auto;

	height: 250px;
	width: 250px;

	z-index: 1;

	filter: brightness(0) saturate(100%) invert(30%) sepia(65%) saturate(2%) hue-rotate(93deg) brightness(96%) contrast(96%);

	pointer-events: none;
}

.navigation-link {
	color: #9393ff;

	text-decoration: none;
    font-size: 16px;

    text-align: left;
}


/* @media (width < 550px) or (height < 550px) {
	
	.navigation-circle-outer-container {
		background-color: red;
	}
} */