.login {
    display: flex;

    height: 100%;
    width: 100%;
}

.login-form {
    display: flex;
    flex-direction: column;
    gap: 24px;

    border: 1px solid black;
    border-radius: 4px;
    padding: 64px 48px;
    
    margin: auto;
}

.login-form-heading {
    margin: 0;
}

.login-form-input-container {
    display: flex;
    flex-direction: column;

    gap: 12px;
}

.login-form-input {
    height: 24px;
}

.login-form-button {
    height: 24px;

    cursor: pointer;
}
