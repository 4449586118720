.gallery {

}

.gallery-container {
	display: flex;
	flex-wrap: wrap;

	gap: 16px;
	justify-content: flex-start;

	margin: 16px;
}

.gallery-fullsize-container {
	display: none;
	
	position: fixed;
	top: 0;
	
	height: 100%;
	width: 100%;

	background-color: rgba(85, 85, 85, 0.2);
	-webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);

	z-index: 100;
}

.gallery-fullsize-container.visible {
	display: flex;
}

.gallery-fullsize-img-container {
	display: flex;
	flex-direction: column;
	justify-content: center;

	width: fit-content;
	max-width: 80%;
	height: 100%;
	max-height: 80%;

	margin: auto;

	z-index: 200;
}

.gallery-fullsize-img {
	max-height: 100%;
	max-width: 100%;
	
	object-fit: contain;
}